<template>
  <div>
    <h2>Bewehrung zum aussteifenden Bauteil</h2>
    <p>Zum aussteifenden Bauteil hin wird aus der Auflagerkraft "FEd" unter Berücksichtigung der Mindestbewehrung die Ankerbewehrung ermittlelt.</p>
  </div>
</template>

<script>
export default {
  name: "ResultTabFourHelp"
};
</script>

<style lang="scss">
</style>